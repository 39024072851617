<!-- ------------------------------------------------------------------------ TEMPLATE START -------------- -->
<template>
  <div>
    <div class="container">
      <div v-if="$root.redirecting" class="row">
        <p>{{ $t("redirecting") }}</p>
      </div>

      <h1 v-if="!this.isIframe" class="mb-5 mt-5">{{ $t("subheader") }}</h1>
      <p class="lead mb-5">{{ $t("intro") }}</p>

      <h2 class="mb-5">{{ $t("idLikeToCreateComplaint") }}</h2>

      <form
        id="barcodeForm"
        method="post"
        enctype="multipart/form-data"
        novalidate
        @submit.prevent="this.validateCodeAndGetToken()"
      >
        <div class="row">
          <div class="col-lg-6">
            <label for="appType">{{ $t("choosePersonType") }}</label>
            <div class="input-wrap">
              <div class="input-shape">
                <select
                  name="appType"
                  id="appType"
                  v-model="$root.appType"
                  class="form-select"
                  :disabled="this.barcodeValid"
                  @change="this.validateBarcodeAndVerify()"
                >
                  <option hidden disabled selected value>
                    {{ $t("notSelected") }}
                  </option>
                  <option
                    v-for="(appType, appTypeKey) in this.personTypes"
                    :key="appType"
                    :value="appTypeKey"
                  >
                    {{ $t(appType) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$root.appType === 'client'" class="row">
          <div class="col-12">
            <p>{{ $t("clientSectionRedirectWarning") }}</p>
            <div class="col-lg-6">
              <div class="input-wrap">
                <div class="btn-shape">
                  <button
                    class="btn btn-submit container"
                    @click="openClientSectionInNewWindow()"
                  >
                    {{ $t("clientSectionRedirectButton") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$root.appType !== 'client'">
          <div class="row" v-if="$root.appType">
            <div class="col-lg-6">
              <label for="barcode">{{ $t("navPackageNo") }}</label>
              <div
                :class="
                  'input-wrap' +
                  ($root.wasFocused('barcode')
                    ? $root.hasError('barcode')
                      ? ' is-invalid'
                      : ' is-valid'
                    : '')
                "
              >
                <div class="input-shape">
                  <input
                    type="text"
                    id="barcode"
                    name="barcode"
                    placeholder="Z 123 4567 890"
                    pattern="Z{1} \d{3} \d{4} \d{3}"
                    :maxlength="maxFullLength"
                    autocomplete="new-password"
                    v-model="$root.barcode"
                    @paste="this.validateBarcodeAndVerify()"
                    @keyup="this.validateBarcodeAndVerify()"
                    @focusout="this.validateBarcodeAndVerify()"
                    :class="
                      'form-control' +
                      ($root.wasFocused('barcode')
                        ? $root.hasError('barcode')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                    :disabled="this.barcodeValid"
                  />
                  <button
                    v-if="this.barcodeValid"
                    type="button"
                    class="input-group-text-right fw-bold bg-danger text-white bg-danger"
                    @click="this.resetBarcode()"
                  >
                    x
                  </button>
                </div>
              </div>
              <InputErrorComponent field="barcode" :errors="$root.errors" />
            </div>
          </div>

          <div class="row" v-if="this.barcodeValid">
            <div class="col-lg-6">
              <label for="reason" class="form-label">{{
                $t("labelComplaintType")
              }}</label>
              <div class="input-wrap">
                <div class="input-shape">
                  <select
                    id="reason"
                    name="reason"
                    aria-label="form-select"
                    v-model="$root.reason"
                    required
                    :disabled="$root.formDisabledForce"
                    class="form-select"
                    @keyup="$root.onKeyUp('reason')"
                    @focusin="$root.onFocusIn('reason')"
                    @focusout="$root.onFocusOut('reason')"
                    @change="$root.onFocusOut('reason')"
                  >
                    <option hidden disabled selected value>
                      {{ $t("notSelected") }}
                    </option>
                    ;
                    <template v-for="(item, index) in $root.enums.reasons">
                      <option
                        :key="index + '1'"
                        :value="item.id"
                        v-if="$root.isShowReasonDelay && item.id === 'delay'"
                      >
                        {{ item.label }}
                      </option>
                      <option
                        :key="index + '2'"
                        :value="item.id"
                        v-else-if="item.id !== 'delay'"
                      >
                        {{ item.label }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
              <InputErrorComponent field="reason" :errors="$root.errors" />
            </div>
          </div>

          <div class="row" v-if="this.barcodeValid">
            <div class="col-12">
              <label for="code">{{ $t("smsAuthentication") }}</label>
              <div class="row">
                <p>
                  <strong>{{ $t("addresseeSmsCodeText") }}</strong>
                </p>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="input-wrap">
                    <div class="btn-shape">
                      <button
                        type="button"
                        id="resendCodeButton"
                        class="btn btn-submit btn-grey container"
                        @click="this.resendCode($event)"
                      >
                        {{ $t("sendCodeAgain") }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div
                    :class="
                      'input-wrap' +
                      ($root.wasFocused('code')
                        ? $root.hasError('code')
                          ? ' is-invalid'
                          : ' is-valid'
                        : '')
                    "
                  >
                    <div class="input-shape">
                      <input
                        type="text"
                        name="code"
                        id="code"
                        v-model="$root.code"
                        :placeholder="$t('codeFromSms')"
                      />
                    </div>
                  </div>
                  <InputErrorComponent field="code" :errors="$root.errors" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="input-wrap">
                <div class="btn-shape">
                  <input
                    type="submit"
                    class="btn btn-submit container"
                    :value="$t('createClaim')"
                    :disabled="!this.barcodeValid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<!-- ------------------------------------------------------------------------ TEMPLATE END ---------------- -->

<script>
import { API_CONFIG } from "@/constants/api_config.js";
import { getPersonTypesByLanguage } from "@/constants/person_types";
import $ from "jquery";
import { APP_CONFIG } from "@/constants/app_config";
import InputErrorComponent from "@/components/InputErrorComponent";

export default {
  name: "Guidepost",
  components: {
    InputErrorComponent,
  },
  props: {},
  data() {
    return {
      maxFullLength: APP_CONFIG.max_full_length,
      type: this.type,
      subtype: this.subtype,
      personTypes: getPersonTypesByLanguage(this.$root.$i18n.locale),
      barcodeValid: this.barcodeValid,
      isIframe: this.$route.query.isIframe,
    };
  },
  methods: {
    validateBarcodeAndVerify() {
      let vue = this;
      let root = this.$root;
      let url = API_CONFIG.api_base_url + API_CONFIG.capture_token;
      let formData = $("form#barcodeForm").serializeJSON();

      if ($.isEmptyObject(formData)) {
        return;
      }

      let barcode = root.transformBarcode(formData.barcode ?? root.barcode);
      root.onKeyUpPackageId("barcode");

      if (barcode.length < 10 || root.barcodeBeingValidated === true) {
        return;
      }

      root.barcodeBeingValidated = true;

      let payload = {
        barcode: barcode,
        type: formData.type ?? root.appType,
      };

      $.ajax({
        url: url,
        method: "POST",
        dataType: "json",
        data: JSON.stringify(payload),
        headers: {
          "Content-Language": root.localeFull[root.$i18n.locale],
          Authorization: "Bearer " + API_CONFIG.bearer_token,
        },
        beforeSend: function () {
          root.appLoaded = false;
        },
        success: function (data) {
          vue.saveValuesFromTokenCapture(data);
        },
        error: function (xhr) {
          if (xhr.status !== 400) {
            root.globalErrors = [];
            root.globalErrors.push(root.$t("errMsgServerError"));
            root.showGlobalErrorModal();
            return;
          }

          let responseJSON = xhr.responseJSON;
          if (!responseJSON) {
            root.globalErrors = [];
            root.globalErrors.push(root.$t("errMsgServerError"));
            root.showGlobalErrorModal();
            return;
          }

          root.markInvalidFields(responseJSON.errors);
        },
        complete: function () {
          root.appLoaded = true;
          root.markAllVisibleFields("barcodeForm");
          root.barcodeBeingValidated = false;
        },
      });
    },

    openClientSectionInNewWindow() {
      let root = this.$root;
      let url = APP_CONFIG.client_section + "/" + root.$i18n.locale;
      window.open(url, "_blank");
    },

    resendCode(event) {
      let root = this.$root;
      let button = $("#" + event.target.id);

      button.html(root.$t("sent") + ".");
      button.prop("disabled", true);

      this.validateBarcodeAndVerify();
    },

    validateCodeAndGetToken() {
      if (!this.barcodeValid) {
        return true;
      }

      let root = this.$root;

      let url = API_CONFIG.api_base_url + API_CONFIG.create_token;
      let formData = $("form#barcodeForm").serializeJSON();
      formData.type = root.appType;
      formData.barcode = root.barcode;

      $.ajax({
        url: url,
        method: "POST",
        dataType: "json",
        data: JSON.stringify(formData),
        headers: {
          "Content-Language": root.localeFull[root.$i18n.locale],
          Authorization: "Bearer " + API_CONFIG.bearer_token,
        },
        beforeSend: function () {
          root.appLoaded = false;
        },
        success: function (data) {
          let appType =
            root.appType === "mk2c" ? "client" : root.appType || "form";

          window.top.location.href =
            "/" + root.locale + "/" + appType + "/" + data.token;
        },
        error: function (xhr) {
          if (xhr.status === 400) {
            let responseJSON = xhr.responseJSON;
            if (responseJSON) {
              root.markInvalidFields(responseJSON.errors);
            } else {
              root.globalErrors = [];
              root.globalErrors.push(root.$t("errMsgServerError"));
              root.showGlobalErrorModal();
            }
          } else {
            root.globalErrors = [];
            root.globalErrors.push(root.$t("errMsgServerError"));
            root.showGlobalErrorModal();
          }
        },
        complete: function () {
          root.appLoaded = true;
          root.markAllVisibleFields("barcodeForm");
        },
      });
    },

    saveValuesFromTokenCapture(data) {
      this.$root.enums.reasons = data.reasons;
      this.barcodeValid = true;
    },

    resetBarcode() {
      let root = this.$root;

      root.barcode = null;
      root.reason = null;
      this.barcodeValid = false;
    },
  },
  beforeUpdate() {},
  mounted() {
    this.$root.appLoaded = true;
  },
};
</script>
<!-- ------------------------------------------------------------------------ SCRIPT START ---------------- -->
<!-- ------------------------------------------------------------------------ SCRIPT END ------------------ -->

<!-- ------------------------------------------------------------------------ STYLES START ---------------- -->
<style lang="scss" scoped></style>
<!-- ------------------------------------------------------------------------ STYLES END ------------------ -->
