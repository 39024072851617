/* APP config */
export const APP_CONFIG = {
  client_section: process.env.VUE_APP_CLIENT_SECTION_URL,
  // max_raw_length: 10,
  // max_full_length: 14,
  max_raw_length: 50,
  max_full_length: 50,
  max_code_length: 4,
  affidavit_min_length: 2,
  err_codes: {
    bad_request: "badRequest",
    missing_field: "missingField",
    not_blank: "notBlank",
    max_size_files: "maxSizeFiles",
    packet_not_found: "packetNotFound",
    min_length_invalid: "minLengthInvalid",
    bank_account_invalid: "bankAccountInvalid",
    type_decimal_invalid: "typeDecimalInvalid",
    not_found: "notFound",
  },
  max_claimed_items: 50,
};
